import { useContext, useEffect, useState } from "react";
import { ConfigContext } from "../../../Context/ConfigContext";
import slugify from "slugify";
import { useTranslation } from "react-i18next";
import Carousel from 'react-multi-carousel';
import { AddToCart } from "../../../Helper/MyCart";
import { ToastContainer, toast } from 'react-toastify';
import { AddToCartNotifier } from "../../../Components/CartComponents";
import { NavLink, useNavigate } from "react-router-dom";
const EquivalentParts = ({STR_ID}) => {
    
    const {gdcURL, apiURL, setCartItemsQty} = useContext(ConfigContext);
   
    const locale = localStorage.getItem('lang');
    const dir = localStorage.getItem('dir');
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [parts, setParts] = useState([]);
    const [showCriteria, setShowCriteria] = useState({});
    const navigate = useNavigate();
    const handleAddToCart = async(part) => {
      
        const partImg = part.MEDIA_SOURCE ? `${gdcURL}/images/${part.MEDIA_SOURCE}` : `${apiURL}/public/placeholder_category.jpeg`;
        const url = `/Spare-Parts/Details/${slugify(`${part.PRODUCT_GROUP}`.toLowerCase())}-${slugify(`${part.ART_ARTICLE_NR}`.toLowerCase())}-${STR_ID}-${slugify(`${part.SUP_ID}`.toLowerCase())}-${slugify(`${part.ART_ID}`.toLowerCase())}`;
        await AddToCart(part.ART_ID,part.ART_ARTICLE_NR, part.PRODUCT_GROUP, part.mrp??0, part.price??0,  1, partImg, url);
        setCartItemsQty();
        
        
        toast.success(<AddToCartNotifier item_name={part.PRODUCT_GROUP}  item_img={partImg}/>, {
          onClose: () => {
           
          }
        })
        
        
      }
    const toggleCriteria = (index) => {
        setShowCriteria((prevShowCriteria) => ({
          ...prevShowCriteria,
          [index]: !prevShowCriteria[index],
        }));
      };
    const [responsive, setResponsive] =  useState({
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2,
          slidesToSlide: 1 // optional, default to 1.
        }
      })
    useEffect(() => {
        if(localStorage.getItem('pc_id'))
        {
          setLoading(true);   
        fetch(`${gdcURL}/Parts/GetPartsByCar?pc_id=${localStorage.getItem('pc_id')}&str_id=${STR_ID}&lang=${locale}`)
        .then((res) => res.json())
        .then((data) => {
            setParts(data.data);
            setLoading(false)
        })
        .catch((error) => {
            console.error('Fetch error:', error);
            setLoading(false);
        });
        }
    }, [STR_ID])
    return (
        <div className="container">
            <div className="pb-5 mt-5" dir={dir} style={{textAlign: dir === 'rtl'? 'right': 'left'}}>
                <h4>{t('fully_equivalent_products')}</h4>
                <Carousel 
                          swipeable={false}
                          draggable={false}
                          showDots={false}
                          responsive={responsive}
                          
                          infinite={false}
                          autoPlaySpeed={1000}
                          keyBoardControl={true}
                          customTransition="all .5"
                          transitionDuration={500}
                          containerClass="carousel-container"
                          removeArrowOnDeviceType={["tablet", "mobile"]}
                          // deviceType={this.props.deviceType}
                          dotListClass="custom-dot-list-style"
                          itemClass="carousel-item-padding-40-px">
                            {
                                parts.map((part, index) => {
                                    const url = `/Spare-Parts/Details/${slugify(`${part.PRODUCT_GROUP}`.toLowerCase())}-${slugify(`${part.ART_ARTICLE_NR}`.toLowerCase())}-${STR_ID}-${slugify(`${part.SUP_ID}`.toLowerCase())}-${slugify(`${part.ART_ID}`.toLowerCase())}`;
                                    const partImg = part.MEDIA_SOURCE ? `${gdcURL}/images/${part.MEDIA_SOURCE}` : `${apiURL}/public/placeholder_category.jpeg`;
                              
                                    return (
                                      <div className="card m-4" style={{ height: '400px' }} key={index}>
                                        <div className="p-3" style={{ position: 'absolute', height: '396px', width: '100%', backgroundColor: '#FFF', display: showCriteria[index]?'unset': 'none' }}>
                                          <div style={{ textAlign: 'right' }}><i className="fas fa-times-circle" onClick={() => toggleCriteria(index)}></i></div>
                                          {part.ARTICLE_CRITERIA && (
                                            <table className="w-100" dir={dir} style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }}>
                                              <tbody>
                                                {part.ARTICLE_CRITERIA.split(';').map((item, indixSub) => {
                                                  const crRow = item.split(':');
                                                  return <tr key={`info-tr-${indixSub}`}><td><span>{crRow[0]}</span></td><td><span>{crRow[1]}</span></td></tr>;
                                                })}
                                              </tbody>
                                            </table>
                                          )}
                                          <button className="btn btn-outline-primary" style={{ textTransform: 'capitalize', bottom: '10px', position: 'absolute' }}>{t('Details')} <i className="fas fa-long-arrow-alt-right"></i></button>
                                        </div>
                                        <div style={{display: !showCriteria[index]?'unset': 'none'}}>
                                            <div onClick={() => {navigate(url); window.scrollTo(0, 0);}} style={{height: '150px', width: '100%', backgroundImage: `url('${partImg}')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}></div>
                                            {/* <img src={partImg} height={100} alt={`${part.ART_SUP_BRAND} ${part.PRODUCT_GROUP} ${part.ART_ARTICLE_NR}`} /> */}
                                            <div className="p-3">
                                                <div className="ratings-container" style={{width: '100%'}}>
                                                    
                                                
                                                    <div className="product-ratings mr-3 ml-3">
                                                        <span className="ratings" style={{width: '100%'}} />
                                                    
                                                        <span className="tooltiptext tooltip-top">5.00</span>
                                                    </div>
                                                    <span style={{ top:0, right: '10px', position: 'absolute', color: '#e65905' }}><i className="sicon-info" onClick={() => toggleCriteria(index)}></i></span>
                                                </div>
                                            <div style={{height: '70px'}} onClick={() => {navigate(url); window.scrollTo(0, 0);}}>
                                                <NavLink to={() => {navigate(url); window.scrollTo(0, 0);}}><h6 style={{ padding: 0, margin: 0 }}>{part.ART_SUP_BRAND} {part.PRODUCT_GROUP} {part.ART_ARTICLE_NR}</h6></NavLink>
                                                <small>{t('article_number', { value: part.ART_ARTICLE_NR })}</small>
                                            </div>
                                            <h4 onClick={() => {navigate(url); window.scrollTo(0, 0);}} style={{ padding: 0, margin: 0 }}>00.0</h4>
                                            <small>{t('price_included_vat_excluded_delivery')}</small>
                                            <center><button onClick={() => handleAddToCart(part)  } className="btn btn-dark add-cart mt-1 btn-sm text-light" title={t('add_to_cart')} > {t('add_to_cart')} </button></center>
                                            </div>
                                        </div>
                                      </div>
                                    );
                                  })
                            }

                          </Carousel>
                
            </div>
        </div>
    )
}
export default EquivalentParts;