import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { ConfigContext } from "../../Context/ConfigContext";
import axios from "axios";
import slugify from "slugify";

const Suppliers = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const locale = localStorage.getItem('lang');
    const {gdcURL, PCID} = useContext(ConfigContext);
    const dir = localStorage.getItem('dir');
    const [suppliers, setSuppliers] = useState([]);

    const sectionRefs = useRef({});

    const handleScrollToSection = (id) => {
        if (sectionRefs.current[id]) {
        sectionRefs.current[id].scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        var config = {
            method: 'get',
            url: `${gdcURL}/Suppliers/GetSuppliers?lang=${locale}&pc_id=${localStorage.getItem('pc_id')}`,
            headers: { }
        };

        axios(config)
        .then(function (response) {
            response.data.sort((a, b) => a.SUP_BRAND.localeCompare(b.SUP_BRAND));

            // Initialize a Map to hold the categorized data
            const map = new Map();
            map.set("09", []);

            // Group data by the initial letter of SUP_BRAND
            response.data.forEach(item => {
                const initial = item.SUP_BRAND[0].toUpperCase();
                if (/\d/.test(initial)) {
                    map.get("09").push(item);
                } else {
                    if (!map.has(initial)) {
                        map.set(initial, []);
                    }
                    map.get(initial).push(item);
                }
            });

            // Convert the Map to an array, sort by keys, and update state
            const sortedEntries = new Map([...map.entries()].sort());

            setSuppliers(sortedEntries);

            
        })
        .catch(function (error) {
        });

    }, [])
    

    return (
        <main className="main" dir={dir}>
            <div className="container">
                <div className="container">
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><NavLink to={'/'}><i className="icon-home" /></NavLink></li>
                        <li className="breadcrumb-item"><NavLink to={() => {}}>{t('home')}</NavLink></li>
                        <li className="breadcrumb-item active" aria-current="page"></li>
                    </ol>
                </nav>
                </div>
                <div className="container">
                    <div className="col-lg-12">
                    <hr style={{margin: '8px'}}/> <br />
                    {
                        suppliers && <div className="row justify-content-center" >
                            {[...suppliers.entries()].map(([key, value]) => (
                                <div className=""  key={key} onClick={() => handleScrollToSection(key)} style={{cursor: 'pointer', paddingLeft: '10px'}}>
                                    <h5 style={{color: '#e65a05'}}>{key}</h5>
                                </div>
                            ))}
                        </div>
                    }
                    </div>
                </div>
                

                <div className="container">
                {
                    suppliers && <div >
                        {[...suppliers.entries()].map(([key, value]) => (
                            <div className="col-lg-12" 
                            id={key}
                            ref={(el) => (sectionRefs.current[key] = el)}
                            key={key}>
                                <hr style={{margin: '8px'}}/>
                                <h3 style={{color: '#e65a05', margin: 0, paddingLeft: '10px'}}>{key}</h3>
                                <hr style={{margin: '8px'}}/>
                                <div className="row">
                                    {value.map(item => (
                                        <div className="col-3 col-md-1" key={`supplier-logos-${item.SUP_ID}`} style={{margin: '5px'}}>
                                           <NavLink to={`/Supplier-Catelog/${item.SUP_ID}-${slugify(item.SUP_BRAND)}`}>
                                            <ImageWithFallback src={`${gdcURL}/sup-logo/${item.SUP_LOGO_NAME}`} alt={item.SUP_BRAND} index={item.SUP_ID} fallbackText={item.ART_SUP_BRAND}/>
                                            </NavLink>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                }
                </div>
            </div>
        </main>
    )
}
const ImageWithFallback = ({ src, index, alt, fallbackText }) => {
    const [imgError, setImgError] = useState(false);
  
    const handleError = () => {
      setImgError(true);
    };
    const fallbackStyle = {
      textAlign: 'center',
      fontSize: '10px',
      padding: '10px',
      wordBreak: 'break-word',
      maxWidth: '90%',
      
    };
  
    const containerStyle = {
      position: 'relative',
      width: '80px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid #CCC',
      padding: '11px'
    };
    return (
      <div style={containerStyle}>
        {imgError ? (
          <div style={fallbackStyle}>{fallbackText}</div>
        ) : (
          <img style={{height: '25px', float: 'none'}} key={`img-part-${index}`} src={src} alt={alt} onError={handleError}  />
         
        )}
      </div>
    );
  };
export default Suppliers;