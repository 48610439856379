import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'

import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../Context/ConfigContext';
import slugify from 'slugify';
import CarSeachBoxHorizontal from '../../Layout/CarSeachBoxHorizontal';
import CarSearchBox from '../../Layout/CarSeachBox';
const SupplierCatelog  = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const locale = localStorage.getItem('lang');
    const {gdcURL, PCID} = useContext(ConfigContext);
    const dir = localStorage.getItem('dir');
    const {slug} = useParams();
    const [categories, setCategories] = useState([]);
    const [car, setCar] = useState([]);
    const [loading, setLoading] = useState(false);
    const [stridtree, setSTRIDTree] = useState([null]);
    
    useEffect(() => {
      window.scrollTo(0, 0)
      if(slug){
        try{
          const sup_id = slug.split('-')[0];
        fetch(`${gdcURL}/Suppliers/GetCategoriesBySupplier?pc_id=${PCID}&sup_id=${sup_id}&lang=${locale}`)
          .then((res) => res.json())
          .then((data) => {
            setCategories(data.data);
           
          })
        }catch(err){
         
        }
      }
         
      }, [PCID]);
    
    const AddCategorySTRID = (str_id) => {
        setSTRIDTree([str_id, ...stridtree])
    }
    const RemoveCategorySTRID = () => {
        if( stridtree[0] !== null){
            setSTRIDTree(stridtree.slice(1));
        }
    }
    const findChildrens = (prent_id) => {
        var obj = categories.find((item) => item.STR_ID_PARENT === prent_id)
        return obj?true:false;
    }
    
    return (
        <main className="main">   
          <div className="parts p-5">
            <div className="container">
            {!localStorage.getItem('pc_id') && 
                  <div className='container mb-1 mt-1 pb-2 pt-2'>
                    <div className='alert alert-info' style={{textAlign: 'center'}}>
                    <div className='col-sm-12'>
                      {
                        localStorage.getItem('lang') === null || localStorage.getItem('lang') !== 'ar' ?
                        <div>
                          To ensure you get the right parts that fit your car perfectly, please select your car before exploring our wide range of products. 
                      This helps us tailor the best options specifically for your make and model, guaranteeing a perfect fit and optimal performance every time.
                      <br /><b>Select Your Car</b> Now and start shopping with confidence!</div>
                      :<div>
                        <b>للحصول على القطع المناسبة لسيارتك!</b>
                        <br />
  لتضمن حصولك على القطع التي تناسب سيارتك تمامًا، يرجى اختيار سيارتك قبل استكشاف مجموعة منتجاتنا الواسعة. يساعدنا هذا في تقديم أفضل الخيارات التي تتناسب مع طراز سيارتك لضمان ملاءمة مثالية وأداء أمثل في كل مرة.
  <br />
  <b>اختر سيارتك الآن وابدأ التسوق بثقة!</b>
                        </div>
                      }
                    </div>
                    </div>
                    <CarSearchBox redirectStatus={true}/>
                  </div>

                  
                  }
              <div className="flex-wrap d-flex justify-content-between align-items-center">
                {
                    stridtree[0] !== null?<h4 onClick={() => RemoveCategorySTRID()}> <i className="fas fa-arrow-left mr-3 my-icon"></i>{" "} </h4>: <div></div>
                }
                
              </div>
              {
              categories && categories.length > 0  && 
              <div dir={dir} className='container' style={{padding: '20px', backgroundColor: '#FFF'}}>
                <div className='row'>
                <div className='col-sm-1'>
                  <img src={`${gdcURL}/sup-logo/${categories[0].SUP_LOGO_NAME}`} alt={categories[0].STR_NODE_NAME}  style={{width: '50px'}}/>
                </div>
                {
                  localStorage.getItem('lang') === 'en' || localStorage.getItem('lang') === null || localStorage.getItem('lang') === ''?
                
                <div className='col-sm-11'>Discover unbeatable prices on top {categories[0].SUP_NAME} automotive parts at Carz7 and secure the lowest prices on high-demand {categories[0].SUP_NAME} car parts. Find the best deals on widely-used {categories[0].SUP_NAME} parts, ensuring you get the finest quality for your car at an amazing value. </div>
              :  
              <div className='col-sm-11' style={{textAlign: 'right'}}>
                اكتشف أفضل الأسعار على قطع غيار السيارات من {categories[0].SUP_NAME} في Carz7 واحصل على أقل الأسعار على قطع غيار السيارات من {categories[0].SUP_NAME} ذات الطلب العالي. ابحث عن أفضل العروض على قطع غيار {categories[0].SUP_NAME} الشائعة، لضمان حصولك على أعلى جودة لسيارتك بأفضل قيمة.
                 </div>
              }
                </div>
              </div>
              }
              <div className="row mt-3">
                {
                    categories && categories.map((item) => {
                        if(item.STR_ID_PARENT === stridtree[0] && item.STR_ID !== 100343 && item.STR_ID !== 706365 && item.STR_ID !== 100019){
                            var isChildren = findChildrens(item.STR_ID);
                            return <div key={item.STR_ID} className="col-md-3" onClick={() => 
                            {
                              if(isChildren){
                                AddCategorySTRID(item.STR_ID)
                                window.scrollTo(0, 0)
                              }else{
                                navigate(`/Spare-Parts-Supplier/${slugify(item.STR_NODE_NAME)}-${item.STR_ID}/${slug.split('-')[0]}/${item.SUP_NAME}`)
                                window.scrollTo(0, 0)
                              }
                            }
                            }>
                                <div className="card border-0 shadow-hover"  >
                                    <div className="card-body d-flex flex-column justify-content-center align-items-center">
                                    <img
                                    src={`${gdcURL}/category-images/600x600/${item.STR_ID}`}
                                    className="img-fluid"
                                    alt={item.STR_NODE_NAME}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // Prevents infinite loop in case fallback also fails
                                      currentTarget.src = "https://placehold.co/100x100/FFFFFF/FFFFFF/png";
                                    }}
                                    
                                  />
                                  <img src={`${gdcURL}/sup-logo/${item.SUP_LOGO_NAME}`} alt={item.STR_NODE_NAME} style={{position: 'absolute', width: '40px', left: '10px', top: '10px' }} />
                                    </div>
                                    <div className="card-text text-center pl-2 pr-2 mb-1">
                                        <h6>{item.SUP_NAME} {item.STR_NODE_NAME}</h6>
                                    </div>
                                </div>
                            </div>
                            
                        }
                    })
                }
              </div>
            </div>
          </div>
        </main>
      
    )
 
}

export default SupplierCatelog;