import Invoice from "../Components/Invoice/Invoice";
import Aboutus from "../Pages/Aboutus/Aboutus";
import Account from "../Pages/Account/Account";
import AccountDetails from "../Pages/Account/AccountDetails";
import Addresses from "../Pages/Account/Addresses";
import Downloads from "../Pages/Account/Downloads";
import ManageAddress from "../Pages/Account/ManageAddress";
import MyOrders from "../Pages/Account/MyOrders";
import OrderDetails from "../Pages/Account/OrderDetails";
import Signup from "../Pages/Account/Signup";
import CarVin from "../Pages/Cars/CarVIn";
import Cart from "../Pages/Carts/Cart";
import Checkout from "../Pages/Carts/Checkout";
import Success from "../Pages/Carts/Success";
import Home from "../Pages/Home/Home";
import MapHome from "../Pages/Map/MapHome";
import OEPartDetails from "../Pages/OESpareParts/OEPartDetails/OEPartDetails";


import Catelog from "../Pages/SpareParts/Catelog/Catelog";
import MakesModels from "../Pages/SpareParts/MakesModels";
import PartDetails from "../Pages/SpareParts/PartDetails/PartDetails";

import Parts from "../Pages/SpareParts/Parts";
import PartsBySuppliers from "../Pages/SpareParts/PartsBySuppliers";
import SupplierCatelog from "../Pages/SpareParts/SupplierCatelog";
import Suppliers from "../Pages/SpareParts/Suppliers";
import WishList from "../Pages/WishList/WishList";

const PrivateRoute = ({ element, ...rest }) => {
  const userLoggedIn = localStorage.getItem('customer_id') === ''|| localStorage.getItem('customer_id') === null || localStorage.getItem('customer_id') === '0'?false:true;
  return ( userLoggedIn?element: <Signup />)
};
// routes.js
const routes = [
  { path: '/', element: <Home /> },
  { path: '/MapTest', element: <MapHome /> },

  // <Route path="/Signup/" element={<Signup />} />
  { path: '/Signup', element: <Signup /> },

  { path: '/Cart', element: <Cart /> },
  
  { path: '/Aboutus', element: <Aboutus /> },
  { path:'/WishList', element:<PrivateRoute element={<WishList />} /> },
  { path: '/Checkout', element: <Checkout /> },
  { path: '/OrderInformation/:order_uid', element: <Success /> },
  { path: '/Invoice', element: <Invoice /> },

  ////////////////////// Master's Routing ///////////////////////////

  // { path:'/Part/:part_uid/:part_id/:part_title_en/:PartNumber', element:<PartDetail /> },

  { path:'/VIN/:vin', element:<CarVin /> },
  { path:'/Spare-Parts/Catelog', element:<Catelog /> },
  { path:'/Spare-Parts/Catelog/:s_str_id?', element:<Catelog /> },
  { path:'/Spare-Parts/:slug?', element:<Parts /> },
  { path:'/Spare-Parts-Supplier/:slug/:sup_id/:sup_name', element:<PartsBySuppliers /> },
  { path:'/Spare-Parts/Details/:part_slug', element:<PartDetails /> },
  
  { path:'/Supplier-Catelog/:slug', element:<SupplierCatelog /> },
  { path:'/Suppliers', element:<Suppliers /> },
  { path:'/Makes/:mf_name/:mfa_id', element:<MakesModels /> },
  
  { path:'/OE-Spare-Parts/Details/:part_slug', element:<OEPartDetails /> },

  ////////////////////// MyAccount Routing ///////////////////////////
  { path:'/MyAccount', element:<PrivateRoute element={<Account />} /> },
  { path:'/MyAccount/Dashboard', element:<PrivateRoute element={<Account />} /> },
  { path:'/MyAccount/Addresses', element:<PrivateRoute element={<Addresses />} />},
  { path:'/MyAccount/ManageAddress', element:<PrivateRoute element={<ManageAddress />} />},
  { path:'/MyAccount/Orders', element:<PrivateRoute element={<MyOrders />} />},
  { path:'/MyAccount/Orders/:order_uid', element:<PrivateRoute element={<OrderDetails />} />},
  { path:'/MyAccount/Downloads', element:<PrivateRoute element={<Downloads />} />},
  { path:'/MyAccount/AccountDetails', element:<PrivateRoute element={<AccountDetails />} />},
];
  
export default routes;
  