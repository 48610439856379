import React, { useContext, useEffect, useState } from 'react'

import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../Context/ConfigContext';
import Carousel from 'react-multi-carousel';
import slugify from 'slugify';
import OEPartsHorizontalCard from './OEPartsHorizontalCard';
const OEParts = ({STR_ID, SELECTED_PTID}) => {
    const location = useLocation();
    const { t } = useTranslation();
    const locale = localStorage.getItem('lang')??'en';
    const {gdcURL} = useContext(ConfigContext);
    const dir = localStorage.getItem('dir');
    const [loading, setLoading] = useState(false);
    const [parts, setParts] = useState([]);
    const [car_info, setCarInfo] = useState(localStorage.getItem('car_info')?JSON.parse(localStorage.getItem('car_info')): []);
    const [responsive, setResponsive] =  useState({
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2,
          slidesToSlide: 1 // optional, default to 1.
        }
      })
      useEffect(() => {
        if(localStorage.getItem('pc_id'))
        {
            
          setLoading(true);   
        fetch(`${gdcURL}/Parts/GetOEPartsByCar?pc_id=${localStorage.getItem('pc_id')}&str_id=${STR_ID}&lang=${locale}&brand=${car_info[0].MFA_BRAND}`)
        .then((res) => res.json())
        .then((data) => {
            
            setParts(data.data);
            setLoading(false)
        })
        .catch((error) => {
            console.error('Fetch error:', error);
            setLoading(false);
        });
        }
    }, [location, STR_ID])
    useEffect(() => {

    }, [SELECTED_PTID])
   return  parts && parts.length > 0 &&  
      parts.map((part, index) => {
        var part_slug = slugify(`${part.PRODUCT_GROUP}`.toLowerCase())+'-'+part.ARL_BRA_BRAND+'-'+slugify(`${part.ARL_SEARCH_NUMBER}`.toLowerCase())+'-'+STR_ID+'-'+part.SUP_ID+'-'+slugify(`${part.ART_ID}`.toLowerCase());
        var url = "/OE-Spare-Parts/Details/"+part_slug;
           return (!SELECTED_PTID || SELECTED_PTID === null || part.PT_ID === SELECTED_PTID) && <OEPartsHorizontalCard key={`r-part-id-${part.ART_ID}-${index}`} partInfo={part} partImg={part.MEDIA_SOURCE} criteria={part.ARTICLE_CRITERIA} url={`/OE-Spare-Parts/Details/${part_slug}`}  id={part.ART_ID}   title={part.PRODUCT_GROUP} number={part.ARL_DISPLAY_NR} price={part.VD_PRICE??0} mrp={part.VD_PRICE_MRP??0}  qty={1} imgUrl={part.MEDIA_SOURCE} action={true}  brand={part.ARL_BRA_BRAND} suplogo={part.SUP_LOGO_NAME} supid={part.SUP_ID} redLabel={3 === 3?`Aftermarket`: 'Genuine'}  />
           return <div key={`oe-part-${index}`} className='card mr-2'>
                 <div style={{height: '200px', backgroundColor: '#EEE'}}>

                 </div>
                 <div className='pl-3 pr-3 pt-3'><h5 className="product-title">{part.PRODUCT_GROUP} {part.ARL_BRA_BRAND} {part.ARL_DISPLAY_NR}</h5></div>
                 <div className="ratings-container">
                   <div className="product-ratings mr-3 ml-3">
                     <span className="ratings" style={{width: '100%'}} />
                     {/* End .ratings */}
                     <span className="tooltiptext tooltip-top">5.00</span>
                   </div>
                   {/* End .product-ratings */}
                 </div>
                 <div className="pl-3 pr-3">
                   <span><i className="far fa-dot-circle" style={{color: 'rgb(11, 207, 28)'}}></i> In stock</span>
                   <h3 className="mt-2" style={{lineHeight:0}}>AED 00.0</h3>
                   <small>price incl. 05% VAT, excl. delivery costs</small>
                 </div>
                 <div className='p-3'>
                  <table>
                    <tr>
                      <td className='w-100'><button className="btn btn-sm btn-dark  mr-2 text-light " title="Add to cart">Add to cart</button></td>
                      <td><NavLink className="btn btn-sm btn-outline-primary" to={url} style={{textTransform: 'capitalize'}}>Details <i className="fas fa-long-arrow-alt-right"></i></NavLink></td>
                    </tr>
                  </table>
                   
                   
                 </div>
                 
               </div>
       })
 
}

export default OEParts;