import { useContext, useEffect, useState } from "react"
import axios from "axios";
import { ConfigContext } from "../../Context/ConfigContext";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import slugify from "slugify";
const Suppliers = () => {
    const [suppliers, setSuppliers] = useState([]);
    const [suppliersLimit, setSuppliersLimit] = useState(30);
    const {gdcURL} = useContext(ConfigContext);
    const [car_info, setCarInfo] = useState(localStorage.getItem('car_info')?JSON.parse(localStorage.getItem('car_info')): []);
    const { t } = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {

        var config = {
            method: 'get',
            url: `${gdcURL}/Suppliers/GetSuppliers?lang=en&pc_id=${localStorage.getItem('pc_id')}`,
            headers: { }
        };

        axios(config)
        .then(function (response) {
            setSuppliers(response.data);
            
        })
        .catch(function (error) {
            
        });

    }, [car_info])
    return <section className="blog-section pb-0">
        <div className="container">
        <h2 className="section-title heading-border border-0" data-animation-name="fadeInUp" > {t('suppliers')} </h2>
        <div className="row justify-content-center">
        {
              suppliers && suppliers.slice(0, suppliersLimit).map((sup, index) => {
                      return sup.SUP_BRAND && <NavLink key={`supplier-logos-${index}`} to={`/Supplier-Catelog/${sup.SUP_ID}-${slugify(sup.SUP_BRAND)}`}>
                          <div className="col-sm-1"  style={{margin: '5px'}}>
                            <ImageWithFallback src={`${gdcURL}/sup-logo/${sup.SUP_LOGO_NAME}`} alt={sup.SUP_BRAND} index={index} fallbackText={sup.ART_SUP_BRAND}/>
                        </div>
                      </NavLink>
                  
                  
              })
          }
        </div>
        <center><button className="btn btn-outline-primary btn-sm" style={{marginTop: '20px'}} type="button" onClick={() => {navigate('/Suppliers'); window.scrollTo(0, 0)}}>{t('show_more')}</button></center>
        </div>
    </section>
}
const ImageWithFallback = ({ src, index, alt, fallbackText }) => {
    const [imgError, setImgError] = useState(false);
  
    const handleError = () => {
      setImgError(true);
    };
    const fallbackStyle = {
      textAlign: 'center',
      fontSize: '10px',
      padding: '10px',
      wordBreak: 'break-word',
      maxWidth: '90%',
      
    };
  
    const containerStyle = {
      position: 'relative',
      width: '80px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid #CCC',
      padding: '11px'
    };
    return (
      <div style={containerStyle}>
        {imgError ? (
          <div style={fallbackStyle}>{fallbackText}</div>
        ) : (
          <img style={{height: '25px', float: 'none'}} key={`img-part-${index}`} src={src} alt={alt} onError={handleError}  />
         
        )}
      </div>
    );
  };
export default Suppliers;