import React, { Component, useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import MobileCartSideBar from "../Components/MobileCartSideBar";
import { ConfigContext } from "../Context/ConfigContext";
import SearchBar from "../Components/SearchBar/SearchBar";
import { useTranslation } from "react-i18next";
import slugify from "slugify";
import { RiFacebookBoxFill, RiInstagramFill, RiTwitterXFill } from "react-icons/ri";
const Header = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dir = localStorage.getItem("dir");
  const { setSideBarStatus, treeCategories, gdcURL, setCategories, CartItemsQty, lang, setLang, CartAmount } = useContext(ConfigContext);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleLangChange = (lang) => {
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
    setLang(lang);
    window.location.reload();
  };
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  useEffect(() => {
    
    fetch(
      `${gdcURL}/Categories/${
        localStorage.getItem("pc_id") !== null &&
        localStorage.getItem("pc_id") !== "" &&
        localStorage.getItem("pc_id") !== 0
          ? "GetCategoriesByCar"
          : "GetCategories"
      }?pc_id=${localStorage.getItem("pc_id")}&lang=${lang}`
    )
      .then((res) => res.json())
      .then((data) => {
        setCategories(data.data);
        
       
      });
      
  }, [null]);
  
  const GetSubMenu = ({ childrens }) => {
    if (childrens.length === 0) {
      return null; // No submenu to render
    }

    return (
      <ul
        className="submenu"
        style={
          dir === "rtl" ? { left: "unset", right: "100%" } : { left: "100%" }
        }>
        {childrens.map((child, index) => (
          <li key={`${child.STR_ID}-${index}`}>
            <NavLink
              to={`/Spare-Parts/${slugify(child.STR_NODE_NAME)}-${
                child.STR_ID
              }`}>
              {child.STR_NODE_NAME}{" "}
              {child.children.length > 0 && (
                <i
                  style={{ float: dir === "rtl" ? "left" : "right" }}
                  className={`fas ${
                    dir === "rtl" ? "fa-chevron-left" : "fa-chevron-right"
                  }`}></i>
              )}
            </NavLink>
            {child.children.length > 0 && (
              <GetSubMenu childrens={child.children} />
            )}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <header className="header">
      <div className="header-top darkColor" style={{color: '#FFF'}}>
        <div className="container">
          <div className="header-left d-none d-sm-block"></div>
          {/* End .header-left */}
          <div className="header-right header-dropdowns ml-0 ml-sm-auto w-sm-100">
            <div className="header-dropdown dropdown-expanded d-none d-lg-block">
              <NavLink to="#">{"Links"}</NavLink>
              <div className="header-menu"></div>
              {/* End .header-menu */}
            </div>
            {/* End .header-dropown */}
            <span className="separator" />
            <div className="header-dropdown mr-auto mr-sm-3 mr-md-0">
              {lang && lang === "en" ? (
                <NavLink to="#">
                  {" "}
                  <i className="flag-us flag" /> ENG{" "}
                </NavLink>
              ) : (
                <NavLink to="#">
                  {" "}
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Logo/language-6.png`}
                    width={"16px"}
                    height={"16px"}
                    alt=""
                  />{" "}
                  {t("arabic")}{" "}
                </NavLink>
              )}

              <div className="header-menu">
                <ul>
                  <li>
                    <NavLink to="#" onClick={() => handleLangChange("en")}>
                      <i className="flag-us flag mr-2" />
                      {t("english")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="#" onClick={() => handleLangChange("ar")}>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/Logo/language-6.png`}
                        width={"16px"}
                        height={"16px"}
                        alt=""
                      />
                      {t("arabic")}
                    </NavLink>
                  </li>
                </ul>
              </div>
              {/* End .header-menu */}
            </div>
            {/* End .header-dropown */}
            <div className="header-dropdown mr-auto mr-sm-3 mr-md-0">
              <NavLink to="#">
                {t(`${localStorage.getItem("currency") ?? "aed"}`)}
              </NavLink>
              <div className="header-menu">
                <ul>
                  <li>
                    <NavLink to="#">
                      <span
                        onClick={() => {
                          localStorage.setItem("currency", "aed");
                          window.location.reload();
                        }}>
                        {t("aed")}
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="#">
                      <span
                        onClick={() => {
                          localStorage.setItem("currency", "usd");
                          window.location.reload();
                        }}>
                        {t("usd")}
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
              {/* End .header-menu */}
            </div>
            {/* End .header-dropown */}
            <span className="separator" />
            <div className="social-icons">
             
              <NavLink to="#" className="social-icon" target="_blank" ><RiFacebookBoxFill /></NavLink>
              <NavLink to="#" className="social-icon" target="_blank" ><RiTwitterXFill /></NavLink>
              <NavLink to="#" className="social-icon" target="_blank" ><RiInstagramFill /></NavLink>
              
            </div>
            {/* End .social-icons */}
          </div>
          {/* End .header-right */}
        </div>
        {/* End .container */}
      </div>
      {/* End .header-top */}
      <div
        className="header-middle lightColor sticky-header"
        data-sticky-options="{'mobile': true}" style={{ borderTop: '0px'}}>
        <div className="container">
          <div className="header-left col-lg-2 w-auto pl-0">
            <button
              className="mobile-menu-toggler text-primary mr-2"
              type="button">
              <i className="fas fa-bars" />
            </button>
            <NavLink to="/" className="logo">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/Logo/car7_transparent_powered_by.png`}
                width={111}
                height={44}
                alt="Carz7 Logo"
              />
            </NavLink>
          </div>
          {/* End .header-left */}

          <div className="header-right w-lg-max">
            <SearchBar />
            {/* End .header-search */}

            <div className="darkColor pl-3 pr-3 pt-1 pb-1">
            <NavLink
              to={localStorage.getItem("customer_id") ? "/MyAccount" : "Signup"}
              className="header-icon"
              title="login">
              <i className="icon-user-2" style={{color: '#bfcbd2'}}></i>
            </NavLink>

            {/* <NavLink to="/Wishlist" className="header-icon" title="wishlist">
              <i className="icon-wishlist-2" style={{color: '#bfcbd2'}}/>
            </NavLink> */}
            <NavLink
              to="/Cart"
              title="Cart"
              style={{ position: "relative", display: "inline-block" }}>
              <i className="minicart-icon" style={{borderColor: '#bfcbd2', marginTop: '-3px'}}/>
              <span className="cart-count badge-circle" style={{marginTop: '-3px'}}>
                {CartItemsQty}
                {/* {CartList && CartList.items && Array.isArray(CartList.items) && CartList.items.length} */}
              </span>
            </NavLink>
            <NavLink
              to="/Cart"
              title="Cart"
              style={{ position: "relative", display: "inline-block" }}>
             
              <div className="ml-3" style={{ color: '#e5eaed',fontSize: '28px', fontFamily: 'Montserrat,Arial,sans-serif'}}>{CartAmount}</div>
            </NavLink>
            </div>
            <div className={`dropdown cart-dropdown show`}>
              {/* <div className="cart-overlay" style={SideCartStatus === true?{position: 'fixed'}: {position: 'unset'}}/> */}
              <MobileCartSideBar />
              {/* End .dropdown-menu */}
            </div>
            {/* End .dropdown */}
          </div>
          {/* End .header-right */}
        </div>
        {/* End .container */}
      </div>
      {/* End .header-middle */}
      <div
        className="header-bottom sticky-header d-none d-lg-block darkColor"
        data-sticky-options="{'mobile': false}"
        style={{ borderBottom: "1px solid rgb(226, 226, 226)", marginBottom: "10px", }}>
        <div className="container">
          <nav
            className="main-nav w-100"
            style={{ textAlign: dir === "rtl" ? "right" : "left" }}>
            
            <ul className="menu">
              <li className="menuItemStyle" onClick={()=>setSideBarStatus(true)} style={ dir === "rtl" ? { float: "right", marginRight: "20px",  color: '#FFF'} : { float: "left" } }>
              <label style={{color: 'rgb(191, 203, 210)', paddingTop: '20px', fontWeight: 700, fontSize: '12px'}}  htmlFor="sidebar-toggle" className="main-nav navbar-toggler sidebar-toggler" aria-expanded="false"
      aria-label="Toggle navigation">
                <svg className="header-nav__icon base-svg mr-2" role="img"  style={{width: '25px',height: '25px', fill: 'rgb(208, 213, 215)'}}>
                  <use href="/assets/images/icon-sprite-bw.svg#sprite-car-icon-full-bw"></use>
                </svg>
                {t("car_parts")}
              </label>
              </li>
              <li className="menuItemStyle" style={ dir === "rtl" ? { float: "right", marginRight: "20px",  color: '#FFF',  display: 'none'} : { float: "left", display: 'none' } }>
                <NavLink to="#" style={{color: '#bfcbd2'}}><img src="/assets/icons/car-parts.png" className="menuIcon" alt="Batteries" style={{height: '30px', marginTop: '-8px'}}/>{t("car_parts")}</NavLink>
                <ul className="submenu">
                  {treeCategories.map((category, index) => (
                    <li key={`${category.STR_ID}-${index}`}>
                      <NavLink to="#" >{category.STR_NODE_NAME}</NavLink>
                      <GetSubMenu childrens={category.children} />
                    </li>
                  ))}
                </ul>
              </li>
              
              <li className="menuItemStyle" style={ dir === "rtl" ? { float: "right", marginRight: "20px" } : { float: "left" } }>
                <NavLink to="/Spare-Parts/Battery-100042" style={{color: '#bfcbd2'}}>
                <svg className="header-nav__icon base-svg" role="img"  style={{width: '25px',height: '25px', fill: 'rgb(208, 213, 215)'}}>
                  <use href="/assets/images/icon-sprite-bw.svg#sprite-electrik-icon-bw"></use>
                </svg>
                {t("Batteries")}
                </NavLink>
              </li>

              <li className="menuItemStyle" style={ dir === "rtl" ? { float: "right", marginRight: "20px" } : { float: "left" } }>
                <NavLink to="/Spare-Parts/Oil-706233" style={{color: '#bfcbd2'}}>
                <svg className="header-nav__icon base-svg" role="img"  style={{width: '25px',height: '25px', fill: 'rgb(208, 213, 215)'}}>
                  <use href="/assets/images/icon-sprite-bw.svg#sprite-oil-icon-bw"></use>
                </svg>
                {t("Oil")}
                </NavLink>
              </li>
              {/* <li className="menuItemStyle" style={ dir === "rtl" ? { float: "right", marginRight: "20px" } : { float: "left" } }>
                <NavLink to="/Spare-Parts/Accessories-100733" style={{color: '#bfcbd2'}}><img src="/assets/icons/tools.png" className="menuIcon" alt="Batteries"/>{t("Accessories")}</NavLink>
              </li> */}
              <li className="menuItemStyle" style={ dir === "rtl" ? { float: "right", marginRight: "20px" } : { float: "left" } }>
                <NavLink to="/Spare-Parts/Accessories-100733" style={{color: '#bfcbd2'}}>
                <svg className="header-nav__icon base-svg" role="img"  style={{width: '25px',height: '25px', fill: 'rgb(208, 213, 215)'}}>
                  <use href="/assets/images/icon-sprite-bw.svg#sprite-misc-icon-bw"></use>
                </svg>
                {t("Accessories")}
                </NavLink>
              </li>

              <li className="menuItemStyle" style={ dir === "rtl" ? { float: "right", marginRight: "20px" } : { float: "left" } }>
                <NavLink to="/Spare-Parts/Tyre-103100" style={{color: '#bfcbd2'}}>
                <svg className="header-nav__icon base-svg" role="img"  style={{width: '25px',height: '25px', fill: 'rgb(208, 213, 215)'}}>
                  <use href="/assets/images/icon-sprite-bw.svg#sprite-tyres-icon-bw"></use>
                </svg>
                {t("Tyres")}
                </NavLink>
              </li>
              <li className="menuItemStyle" style={ dir === "rtl" ? { float: "right", marginRight: "20px" } : { float: "left" } }>
                <NavLink to="/Spare-Parts/Filters-100005" style={{color: '#bfcbd2'}}>
                <svg className="header-nav__icon base-svg" role="img"  style={{width: '25px',height: '25px', fill: 'rgb(208, 213, 215)'}}>
                  <use href="/assets/images/icon-sprite-bw.svg#sprite-filters-icon-bw"></use>
                </svg>
                {t("Filters")}
                </NavLink>
              </li>
              <li className="menuItemStyle" style={ dir === "rtl" ? { float: "right", marginRight: "20px" } : { float: "left" } }>
                <NavLink to="/Spare-Parts/Braking-System-100006" style={{color: '#bfcbd2'}}>
                <svg className="header-nav__icon base-svg" role="img"  style={{width: '25px',height: '25px', fill: 'rgb(208, 213, 215)'}}>
                  <use href="/assets/images/icon-sprite-bw.svg#sprite-brackes-icon-bw"></use>
                </svg>

                {t("Brakes")}
                </NavLink>
              </li>

             
            </ul>
          </nav>
        </div>
        {/* End .container */}
      </div>
      {/* End .header-bottom */}
      
    </header>
  );
};

export default Header;
