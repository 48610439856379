import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { CartProcess, GetCarts } from "../../Helper/MyCart";
import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import { ConfigContext } from "../../Context/ConfigContext";
import Swal from "sweetalert2";
import withRouter from "../../Routes/withRouter";
import { withTranslation } from 'react-i18next';
class Checkout extends Component {
  static contextType = ConfigContext;

  constructor(props) {
    super(props);

    this.state = {
      cartItems: [],
      coupon: localStorage.getItem("Coupon"),
      customers_addresses: [],
      selected_address: [],
      place_order_loading: false,
      payment_method: 'cod'
    };

    this.MySwal = withReactContent(Swal);
  }
  componentDidMount() {
   
    this.getCustomerAddresses();
    
  }

  fetchCartDataFromLocalStorage = async () => {

    if (!this.state.isLoggedIn) {
      
      const { apiURL,gdcURL, UpdateCartList } = this.context;
      const { coupon, selected_address } = this.state;
      
      const { t, i18n } = this.props;
      
      if (coupon) {
        localStorage.setItem("Coupon", coupon);
      }
      try {
        var carts = GetCarts();
        
        if (carts) {
          var address = {"postal_code": selected_address.pincode, "city_name": selected_address.city,  "address": selected_address.complete_address, "country_code": "SA","country_name": selected_address.country}
          // console.log(`${apiURL}/Carts/CheckoutPreview?lang=${i18n.language}&currency=${localStorage.getItem('currency')??'aed'}&customer_id=${localStorage.getItem('customer_id') || 1}&carts=${JSON.stringify( carts )}&address=${JSON.stringify(address)}&coupon=${coupon}`)
          
          const response = await axios.get(`${gdcURL}/Orders/GetCart`, {
            params: {
              currency: localStorage.getItem('currency')??'aed',
              lang: this.context.lang,
              customer_id: localStorage.getItem("customer_id") || 0,
              carts: JSON.stringify(carts),
              coupon: coupon,
              address:JSON.stringify(address)
            },
          });
          
          const data = response.data.data;
          if (data) {
            
            this.setState({ cartItems: data });
            this.setState({ coupon: coupon });
          } else {
            
          }
          // CartProcess(data.items);

          // UpdateCartList(data);
        }
      } catch (err) {
        // console.log("LoadCart err", err);
      }
    }
  };
  getCustomerAddresses = async () => {
    const { apiURL } = this.context;

    try {
      const customer_id = localStorage.getItem("customer_id");
      const { token } = this.context;
      const headers = { token: token, Accept: "application/json", "Content-Type": "application/json", };

      const response = await axios.get(
        `${apiURL}/Account/GetAddresses?customer_id=${customer_id}`,
        { headers }
      );

      const data = response.data;

      if (data.success) {
        this.setState({ customers_addresses:data.data ,selected_address: data.data.find( (item) => item.selected = 1 ) }, (prevState)=>{ 

          this.fetchCartDataFromLocalStorage(); 
        });
        
      } else {
        // console.log("Error While Fetching Customer Addresses", data.message);
      }
    } catch (error) {
      // console.log("Oops Something Went Wrong", error);
    }
  };

  handlePlaceOrder = async () => {
    if(this.state.place_order_loading === true){
      return false;
    }
    const {apiURL, token} = this.context
    const {coupon, customers_addresses}  = this.state;
    const carts = GetCarts();
    const requestData = {
      lang: this.context.lang,
      customer_id: Number(localStorage.getItem('customer_id')),
      carts: JSON.stringify(carts),
      coupon: coupon,
      currency: localStorage.getItem('currency'),
      address_id:  customers_addresses[0].address_id,
      payment_method: this.state.payment_method,
      source_info: {
        ip: "192.168.5.100",
        device: "MAC",
        country: "India",
      },
    };
    // console.log("requestData", requestData);
    
    try {
      this.setState({place_order_loading : true});
      const response = await axios.post(`${apiURL}/Carts/Checkout`, requestData, {
        headers: {
          'token': token,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      
      const data = response.data;
      // console.log('Response:', data);
      
      if(data.success === false){
        this.setState({place_order_loading : false}, (prevState) => console.log("place_order_loading",this.state.place_order_loading));
        
      }
      if(data.success === true){
        this.props.navigate(`/OrderInformation/${data.secret_order_id}`);
      }
      if(data){
        // console.log(data.message);
      }else{
        // console.log(data.message);
      }
    } catch (error) {
      this.setState({place_order_loading : false});
      // console.error("Error placing order:", error);
    }
  };
  render() {
    const { cartItems, customers_addresses, selected_address } = this.state;
    const { apiURL } = this.context;
    const { t } = this.props;
    const dir = localStorage.getItem('dir');
    let Cart =
      cartItems &&
      cartItems.items &&
      Array.isArray(cartItems.items) &&
      cartItems.items.length > 0;
      // console.log("cartItems.items", cartItems.items)
    return (
      <main className="main main-test">
        <div className="container checkout-container">
        <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <ol className="breadcrumb" dir={dir}>
              <li className="breadcrumb-item">
                <NavLink to={'/Cart'}>{t('shopping_cart')}</NavLink>
              </li>
              <li className="breadcrumb-item">
              <NavLink to={'/Checkout'}>{t('checkout')}</NavLink>
              </li>
              <li className="breadcrumb-item">
                <NavLink to="#">{t('order_details')}</NavLink>
              </li>
              
            </ol>
          </nav>
          
          
          <div className="row">
            <div className="col-lg-8" >
              <div style={{display: localStorage.getItem('customer_id')===''?'none':''}}>
              <p><h2 className="step-title" style={{textAlign: dir==='rtl'?'right': 'left'}}>{t('shipping_address')}</h2></p>
              <div className="cart-summary p-2" style={{ borderRadius: "2%" }}>
                {customers_addresses.length > 0 ? (
                  <div className="container">
                          <div className="row">
                            <div className="col-md-1 mt-2">
                              <i className="sicon-location-pin h2 ml-4"></i>
                            </div>
                            <div className="col-md-9">
                              <div className="card-body" style={{minHeight: 'unset'}}>
                                
                              <div key={selected_address.address_id} style={{ lineHeight: "30px" }} >
                                <div className="h5 mb-0"> {selected_address.first_name} {selected_address.last_name} </div>
                                <div className="mt-0">
                                  {selected_address.complete_address},
                                  {selected_address.pincode}, {selected_address.city},
                                  {selected_address.state}, {selected_address.country}
                                </div>
                                { selected_address.mobile_number_2 && selected_address.mobile_number_2 > 0?
                                  <div>
                                  <p> {selected_address.mobile_number_2}{" "} 
                                  {/* <span className="text-primary"> {"Verify now"} </span>  */}
                                  </p>
                                </div>:<></>}
                              </div>
                                
                              </div>
                            </div>
                            <div className="col-md-1 mt-2">
                              <NavLink to={"/MyAccount"} className="text-primary" > <i className="fa fa-edit"></i> </NavLink>
                            </div>
                          </div>
                        </div>
                ) : (
                  <div className="d-flex justify-content-center" style={{height: '100px', padding: '40px'}}>
                    
                    <NavLink to={`/MyAccount/Addresses`}>{t('add_new_address')}</NavLink>
                    
                  </div>
                )}
              </div>
              </div>

              <div className="col-lg-12">
               <p> <h2 className="step-title" style={{textAlign: dir==='rtl'?'right': 'left'}}>{t("your_order")}</h2></p>

                <div
                  className="cart-summary p-2"
                  style={{ borderRadius: "2%" }}
                >
                  <div className="mt-0">
                    <h5 style={{ padding: '5px 10px', marginBottom: '0px' }}>{t('order_summary')} ({cartItems &&
                      cartItems.items && cartItems.items.length}) </h5>
                  </div>
                  <div className="row">
                    {cartItems &&
                      cartItems.items &&
                      Array.isArray(cartItems.items) &&
                      cartItems.items.length > 0 &&
                      cartItems.items.map((item) => (
                        <>
                          <div className="col-lg-6">
                            <div className="d-flex"
                              style={{ padding: "1rem 1rem" }} >
                              {/* <img
                                  src={`${apiURL}/public/placeholder_category.jpeg`}
                                  alt="product"
                                  style={{ width: "90px", height: "auto" }}
                                /> */}
                              <div className="product-default" style={{ marginBottom: '0rem' }} data-animation-name="fadeInRightShorter">
                                <figure>
                                  <NavLink aria-current="page" className="active" to={"/Results/137/Filter-Oil"}>
                                    {/* <img src={`${apiURL}/public/placeholder_category.jpeg`} width="50" height="auto" alt="product" /> */}
                                    <img
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; 
                                      currentTarget.src = "https://placehold.co/40x40/E4E4E4/E4E4E4/png";
                                  }}
                                    src={item.item_img_url} alt={item.item_title} style={{ width: "110px", height: "auto" }}/>
                                    
                                  </NavLink>
                                  
                                </figure>
                                {/* <div className="product-details"><div className="category-list"><NavLink aria-current="page" className="product-category active" to={"/Results/137/Filter-Oil">15601BZ030</a></div><h3 className="product-title"><NavLink aria-current="page" className="active" to={"/Results/137/Filter-Oil">6096</a></h3><h3 className="product-title" style="display: none;"><NavLink aria-current="page" className="active" to={"/Results/137/Filter-Oil">43366</a></h3><h3 className="product-title"><NavLink aria-current="page" className="active" to={"/Results/137/Filter-Oil">Filter Oil</a></h3><div className="ratings-container"><div className="product-ratings"><span className="ratings" style="width: 80%;"></span><span className="tooltiptext tooltip-top"></span></div></div><div className="price-box"><del className="old-price">43.51</del><span className="product-price">43.51</span></div><div className="product-action"><NavLink title="wishlist" aria-current="page" className="btn-icon-wish active" to={"/Results/137/Filter-Oil"><i className="icon-heart"></i></a><button className="btn-icon btn-add-cart"><i className="fa fa-arrow-right"></i><span>ADD TO CART</span></button><NavLink title="Quick View" className="btn-quickview" to={"/Part/6096/43366/Filter_Oil/15601BZ030"><i className="fas fa-external-link-alt"></i></a></div></div></div> */}
                              </div>
                              <div className=" ml-3 text-dark">
                                
                                <div className="show-items">{item.item_title}</div>
                                <span className="h6 ">{item.display_price}</span>
                              </div>
                            </div>
                          </div>

                        </>
                      ))}
                    <div className="col-lg-12">
                      {/* <h5 className="" style={{ padding: "5px 1rem" }}>Delivery by <span className="text-success">Tomorrow</span></h5> */}
                      {/* <div className="border border-danger ml-3 mr-3">
                              <h5>Free Delivered By Tomorrow</h5>
                          </div> */}
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="col-lg-12">
                <p><h2 className="step-title" style={{textAlign: dir==='rtl'?'right': 'left'}}>{t('payment')}</h2></p>
                <div className="cart-summary p-2"  style={{textAlign: dir==='rtl'?'right': 'left', borderRadius: "2%"}}>
                  <table className="table table-bordered" dir={dir}>
                    <tr><td style={{paddingLeft: '20px', padding: '20px'}} onClick={() => this.setState({payment_method: 'online'})}>
                    <div className="form-check">
                      <input checked={this.state.payment_method === 'online'?true:false} style={{transform:'scale(1.5)', marginTop: '7px'}} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                      <label className="form-check-label" style={dir === 'rtl'?{marginRight: '20px'}:{marginLeft: '20px'}} htmlFor="flexRadioDefault1">
                        <h3>{t('online_payment')}</h3>
                      </label>
                    </div>
                    </td></tr>
                    <tr><td style={{paddingLeft: '20px', padding: '20px'}} onClick={() => this.setState({payment_method: 'tabby'})}>
                    <div className="form-check">
                      <input checked={this.state.payment_method === 'tabby'?true:false} style={{transform:'scale(1.5)', marginTop: '7px'}} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                      <label className="form-check-label" style={dir === 'rtl'?{marginRight: '20px'}:{marginLeft: '20px'}} htmlFor="flexRadioDefault1">
                        <h3>{t('tabby')}</h3>
                      </label>
                    </div>
                    </td></tr>
                    <tr><td style={{paddingLeft: '20px', padding: '20px'}} onClick={() => this.setState({payment_method: 'cod'})}>
                    <div className="form-check">
                      <input checked={this.state.payment_method === 'cod'?true:false} style={{transform:'scale(1.5)', marginTop: '7px'}} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                      <label className="form-check-label" style={dir === 'rtl'?{marginRight: '20px'}:{marginLeft: '20px'}} htmlFor="flexRadioDefault1">
                        <h3>{t('cash_on_delivery')}</h3>
                      </label>
                    </div>
                    </td></tr>
                  </table>
                </div>
              </div>
            </div>

            {/* End .col-lg-8 */}
            <div className="col-lg-4">
              <p><h2 className="step-title" dir={dir} style={{textAlign: dir==='rtl'?'right': 'left'}}>{t('order_summary')}</h2></p>
              <div className="cart-summary p-3 mb-1" style={{ borderRadius: "3%", textAlign: dir==='rtl'?'right': 'left', direction: dir }} >
                <h5 style={{ lineHeight: "1.5 rem", marginBottom: "0px", marginLeft: "10px", }} > {t("order_details")} </h5>
                <table className="table table-totals mb-0">
                {Cart &&
                            
                  cartItems.items.map((item) => (
                    <tr key={item.item_id}>
                      <td className="custom-light">
                        {item.item_title} x {item.item_qty}
                      </td>
                      <td className="float-right">
                        {item.total_display_price}
                      </td>
                    </tr>
                  ))}
                  </table>
                {Cart && (
                  <>
                    <table className="table table-totals mb-0">
                      <tbody>
                        <tr style={{ border: "none", marginBottom: "0px" }}>
                          <td style={{textAlign: dir==='rtl'?'right': 'left',}}>{t("sub_total")}</td>
                          <td style={{textAlign: dir!=='rtl'?'right': 'left',}} className="custom-dark">
                            <span>{cartItems.sub_total_display}</span>
                          </td>
                        </tr>

                        <tr style={{ border: "none", marginBottom: "0px" }}>
                          <td style={{textAlign: dir==='rtl'?'right': 'left',}}>{t("shipping")}</td>
                          <td style={{textAlign: dir!=='rtl'?'right': 'left',}} >
                            <span>{cartItems.display_shipping_cost}</span>
                          </td>
                        </tr>
                        

                        {localStorage.getItem("Coupon") &&
                          cartItems.total_saving   && (
                            <tr>
                              <td>{t('total_saving')} <span className="text-success alert-success" style={{ padding: "1px 5px", border: "1px dashed", }} > {localStorage.getItem("Coupon")} </span></td>
                              <td className="custom-dark">
                                <span className="text-success">
                                  - {cartItems.total_saving}
                                </span>
                                
                              </td>
                            </tr>
                          )}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>{t("grand_total")}</td>
                          <td className="custom-dark">
                            <span>{cartItems.grand_total_display}</span>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </>
                )}
              </div>
              <div className="checkout-methods">
                {
                  localStorage.getItem('customer_id') === ''?
                  <span className="alert alert-danger">Please login or create account before place order</span>
                  :<div></div>
                }
                
                <button
                  type="button"
                  style={{ borderRadius: "3%" }}
                  to={"/Checkout"}
                  disabled={localStorage.getItem('customer_id') === ''?true: false}
                  className={this.state.place_order_loading === true?"btn btn-block btn-default":"btn btn-block btn-primary"}
                  onClick={() => this.handlePlaceOrder()}
                >
                  {this.state.place_order_loading === true?"Please wait...":t("place_order")}
                  
                </button>

                
              </div>
              <div className="text-dark" dir={dir} style={{textAlign: dir==='rtl'?'right': 'left'}}>
                <div className="mt-2">
                {(" ")}<i className="fas fa-solid fa-lock mr-1"></i>{(" ")}
                  <strong className="" style={{ color: 'grey' }}>{t("security_and_privacy")}</strong>
                </div>
                <div className="mt-1">
                {t("checkout_safe")}
                </div>
              </div>
            </div>

            {/* End .col-lg-4 */}
          </div>
          {/* End .row */}
        </div>
        {/* End .container */}
      </main>
    );
  }
}

export default withRouter(withTranslation()(Checkout));
