import { Component, useContext, useState } from "react";
import {NavLink, useNavigate} from 'react-router-dom';
import { ConfigContext } from "../../Context/ConfigContext";
// import { AddToCart, GetCarts } from "../../Helper/Carts";
import { AddToCart, GetCarts } from "../../Helper/MyCart";
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation, withTranslation } from 'react-i18next';
import { AddToCartNotifier } from "../CartComponents";

const PartsHorizontalCard = ({partImg,  id,number, title, mrp, price,  qty, imgUrl, url, criteria, displayPrice, brand, supid, partInfo}) => {
      
      const {local, apiURL, gdcURL, setCartItemsQty} = useContext(ConfigContext);
      const [brandImg, setBrandImg] = useState(true);
      const { t } = useTranslation();
      const navigate = useNavigate();
      const dir = localStorage.getItem('dir');
      const [add_to_cart_status, setAdd_to_cart_status] = useState(false);
      const [selectQty, setSelectedQty] = useState(1);
      var partImg = partImg?`${gdcURL}/images/${partImg}`:`${apiURL}/public/placeholder_category.jpeg`
      var partURL = url; 
      const removeBrandImage = () => {
        setBrandImg(false);
      }
      const handleAddToCart = async(slug) => {
        // toast.success('Successfully toasted!');
        
        setAdd_to_cart_status(true)
        // console.log(this.props.id,this.props.number, this.props.title, this.props.mrp, this.props.price,  this.props.qty, this.props.imgUrl, this.props.url)
        await AddToCart(id,number, title, mrp, price,  selectQty, imgUrl, url);
        setCartItemsQty();
        
        
        toast.success(<AddToCartNotifier item_name={title}  item_img={partImg}/>, {
          onClose: () => {
            setAdd_to_cart_status(false)
          }
        })
        // UpdateCartList(GetCarts());
        // ChangeCartProcessStatus(true);
        
      }
      const incrementQty = () => {
        setSelectedQty(prevQty => prevQty + 1);
      };
    
      const decrementQty = () => {
        setSelectedQty(prevQty => (prevQty > 1 ? prevQty - 1 : 1)); // Assuming quantity can't be less than 1
      };
      return <div className="col-sm-12 col-6 product-default left-details product-list mb-2" style={{borderBottom: '1px solid #CCC'}}>
      
      <figure style={{padding: '30px', marginBottom: 'auto'}}>
      
        <a href={partURL}>
          <img src={partImg} width={250} style={{width: '250px', border: '1px solid #CCC'}} height={250} alt="product" />
          <img src={partImg} width={250} style={{width: '250px', border: '1px solid #666'}} height={250} alt="product" />
        </a>
      </figure>
      <div className="product-details responsive-width-part-desc" >
        <div className="category-list">
          <NavLink to={partURL} className="product-category">{number}</NavLink>
        </div>
        <h3 className="product-title"> <NavLink to={partURL}>{title} - {number} - {brand}</NavLink>
        </h3>
        <div className="ratings-container">
          <span className="mt-1">{t('article_number', {value: number})}</span>
      
          <div className="product-ratings mr-3 ml-3">
            <span className="ratings" style={{width: '100%'}} />
            {/* End .ratings */}
            <span className="tooltiptext tooltip-top">5.00</span>
          </div>
          {/* End .product-ratings */}
        </div>
        {/* End .product-container */}
        <p className="product-description w-100 pr-5">
          
        {
              criteria && <table className="table table-striped w-100 mr-4" dir={dir} style={{textAlign: dir === 'rtl'? 'right': 'left'}}>
              <tbody>
              {
                  criteria.split(';').map((item, indixSub) => {
                      var crRow = item.split(':');
                      return <tr key={`info-tr-${indixSub}`}><td><span>{crRow[0]}</span></td><td><span>{crRow[1]}</span></td></tr>
                  })
              }
              </tbody>
              <tfoot><td colSpan={2}><NavLink to={partURL} className="btn btn-outline-primary" style={{textTransform: 'capitalize'}}>{t('Details')} <i className="fas fa-long-arrow-alt-right"></i></NavLink></td></tfoot>
              </table>
            }
            
          </p>
        
        {/* End .price-box */}
        
      </div>
      <div className="product-action" style={{marginBottom: 'auto'}}>
      <div className="price-box">
      <div className='row justify-content-center'>
          <div className='col-md-12 mb-2'>
            {price > 0 && <span ><i style={{color: 'rgb(11, 207, 28)'}} className="far fa-dot-circle"></i> {t('in_stock')}</span>}
            
            {price <= 0 && <h1 className='mt-4' >{t('out_of_stock')}</h1>}
            {price > 0 && <h1 className='mt-4' style={{lineHeight: 1}}>{localStorage.getItem('currency')=== '' || localStorage.getItem('currency')=== 'aed'?t('aed_amount', {amount: price}):t('usd_amount', {amount: price})}</h1>}
            {price > 0 &&  <small>{t('price_included_vat_excluded_delivery')}</small>}
          </div>
          {price > 0 && 
          <div className="col-md-4" style={{width: '100%'}}>
            <div className="input-group mb-3 float-right">
              <div className="input-group-prepend" onClick={decrementQty}>
                <span className="input-group-text"><i className="fas fa-minus"></i></span>
              </div>
              <div type="number" className="form-control no-arrows" value="1" style={{textAlign: 'center', lineHeight: '35px'}} aria-label="Amount (to the nearest dollar)" >{selectQty}</div>
              <div className="input-group-append" onClick={incrementQty}>
                <span className="input-group-text"><i className="fas fa-plus"></i></span>
              </div>
            </div>
          </div>}
          <div className="col-md-7">
          {price > 0 ? 
            <button onClick={() => handleAddToCart()  } className="btn btn-dark add-cart mr-2 text-light " title={t('add_to_cart')} >
              {t('add_to_cart')}
            </button>
            :<button disabled className="btn btn-dark add-cart mr-2 text-light " title={t('add_to_cart')} >
            {t('add_to_cart')}
          </button>}
            
          </div>
        </div>
         
        </div>
          
        </div>
      {/* End .product-details */}
    </div>
}

export default PartsHorizontalCard;